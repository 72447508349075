(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/start-page/views/cookie-info.js') >= 0) return;  svs.modules.push('/modules/start-page/views/cookie-info.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.start_page=_svs.start_page||{};
_svs.start_page.templates=_svs.start_page.templates||{};
svs.start_page.templates.cookie_info = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"padding-1\">\n		<div class=\"panel panel-default\">\n				<div class=\"panel-body\">\n					<div class=\"margin-bottom-1 f-400 bold\">Därför använder vi cookies</div>\n					<p>\n          Svenska Spel använder s.k. cookies på webbplatsen. En cookie är en liten textfil som lagras i din webbläsare. Information som lagras i cookies på Svenska Spels webbplats är en förutsättning för att kunna leverera delar av tjänsten.\n          </p><p>\n          Svenska spel använder både s.k. temporära cookies och beständiga cookies. Temporära cookies försvinner från din webbläsare när du stänger webbläsaren. Dessa används bl.a. för att göra det möjligt att logga in på webbplatsen. Beständiga cookies används bl.a. för att identifiera din webbläsare mellan dina besök på webbplatsen. Dessa används för analysera och förbättra innehåll, tjänster och marknadsföring. Beständiga cookies raderas inte när du stänger ner webbläsaren.\n					</p><p>\n          Vid besök på Svenska Spels webbplats kan samarbetspartners till Svenska Spel, som levererar annonser på uppdrag av Svenska Spel, placera s.k. tredjeparts cookies i webbläsaren. Dessa cookies används för att styra och utvärdera Svenska Spels annonsering på andra webbplatser.\n					</p><p>\n          Svenska Spel sparar inga personuppgifter i cookies. Informationen är inte tillgänglig för andra parter än AB Svenska Spel och våra samarbetspartners.\n					</p><p>\n          Väljer du att inte tillåta lagring av cookies i inställningarna för din webbläsare kommer inga cookies att lagras. Delar av Svenska Spels webbplats kommer då ej att fungera fullt ut.\n					</p><p>\n					Vid frågor kontakta <a href=\"/kundservice/kontakt\">Svenska Spels Kundservice</a>\n					</div>\n		</div>\n</div>\n";
},"useData":true});
Handlebars.partials['svs-start_page-cookie_info'] = svs.start_page.templates.cookie_info;
})(svs, Handlebars);


 })(window);