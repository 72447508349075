(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/start-page/assets/javascripts/start-page_desktop.js') >= 0) return;  svs.modules.push('/modules/start-page/assets/javascripts/start-page_desktop.js');

'use strict';

function avoidHidingElementsOnTopOfPage() {
  const _scrollToTop = () => {
    if (window.scrollY > 0 && window.scrollY < 300) {
      window.scrollTo(window.scrollX, 0);
    }
  };
  window.addEventListener('beforeunload', _scrollToTop);
  window.addEventListener('load', () => setTimeout(_scrollToTop, 0));
}
avoidHidingElementsOnTopOfPage();

 })(window);